export const ERROR_MSG_BASE = '에러는 너굴맨이 처리했다구!';

export const AUTH_TOKEN_NAME = 'USER-KLUE';

export const REFRESH_TOKEN_NAME = 'REFRESH-TOKEN';

export const SEARCH_OFFSET = 10;

export const NICKNAME_ITEM_ID = 4;

export const INQUIRY_TABLE_DEFAULT_OFFSET = 5;

export const NOTICE_TABLE_DEFAULT_OFFSET = 10;
