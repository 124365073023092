import ERROR from '@klue/asset/assets/error.json';
import AuthApi from '@app/apis/AuthApi';
import axios, { AxiosError } from 'axios';
import StorageManager from '@app/utils/StorageManager';
import { AUTH_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../../constants';

export const errorInterceptor = async (error: AxiosError) => {
  const authApi = new AuthApi();

  // AccessToken이 유효하지 않은 경우
  if (
    error.response?.status === 401 &&
    error.response?.data.errorCode === ERROR.AUTH_LOGIN_NEEDED.errorCode
  ) {
    StorageManager.removeItem(AUTH_TOKEN_NAME);
    return Promise.reject(error);
  }

  // RefreshToken 갱신이 필요한 경우
  if (
    error.response?.status === 403 &&
    error.response?.data.errorCode === ERROR.AUTH_EXPIRED_JWT.errorCode
  ) {
    const originalRequest = error.config;
    StorageManager.removeItem(AUTH_TOKEN_NAME);

    try {
      const refreshToken = StorageManager.getItem(REFRESH_TOKEN_NAME);

      if (!refreshToken?.value) {
        return Promise.reject(error);
      }

      const response = await authApi.refreshAccessToken(refreshToken.value);
      const { headers } = response;

      if (!headers?.authorization) {
        return Promise.reject(error);
      }

      StorageManager.setItem(AUTH_TOKEN_NAME, headers.authorization);
      axios.defaults.headers.common.Authorization = headers.authorization;
      originalRequest.headers.Authorization = headers.authorization;
      return axios(originalRequest);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // 그 외 에러
  return Promise.reject(error);
};
