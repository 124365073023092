import { autorun, makeAutoObservable } from 'mobx';
import { PopupNode } from '@app/models/Popup';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

class PopupStore {
  private _popup: PopupNode[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public push(data: PopupNode): void {
    this._popup = [...this._popup, data];
  }

  public pop(): PopupNode | undefined {
    return this._popup.pop();
  }

  public remove(data?: PopupNode) {
    const idx = this._popup.findIndex(item => item === data);
    if (idx < 0) return;
    this._popup.splice(idx, 1);
  }

  get peek(): PopupNode | undefined {
    return this._popup.length <= 0
      ? undefined
      : this._popup[this._popup.length - 1];
  }
}

const popupStore = new PopupStore();

autorun(() => {
  if (popupStore.peek) {
    disableBodyScroll(document.body);
  } else {
    enableBodyScroll(document.body);
  }
});

export default popupStore;
