import {
  AlertPopupConfig,
  ConfirmPopupConfig,
  InputPopupConfig,
  PopupType,
} from '@app/models/Popup';
import popupStore from '@app/stores/PopupStore';

const alert = (
  config: AlertPopupConfig,
  onClose?: () => void | Promise<void>
) => {
  popupStore.push({
    type: PopupType.ALERT,
    popup: {
      message: config.message,
      closeLabel: config.closeLabel,
      onClose,
    },
  });
};

const confirm = (
  config: ConfirmPopupConfig,
  onConfirm?: () => void | Promise<void>,
  onCancel?: () => void | Promise<void>
) => {
  popupStore.push({
    type: PopupType.CONFIRM,
    popup: {
      message: config.message,
      confirmLabel: config.confirmLabel,
      cancelLabel: config.cancelLabel,
      onConfirm,
      onCancel,
    },
  });
};

const input = (
  config: InputPopupConfig,
  onConfirm?: (input: string) => void | Promise<void>,
  onCancel?: () => void | Promise<void>
) => {
  popupStore.push({
    type: PopupType.INPUT,
    popup: {
      message: config.message,
      placeholder: config.placeholder,
      confirmLabel: config.confirmLabel,
      cancelLabel: config.cancelLabel,
      onConfirm,
      onCancel,
    },
  });
};

const Popup = {
  alert,
  confirm,
  input,
};
export default Popup;
