import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import StorageManager from '@app/utils/StorageManager';
import PopupPortal from '@app/portals/popup/PopupPortal';

import { useAuthInfoQuery } from '@app/queries/authApi';
import { alertApiError } from '@app/utils';
import { AUTH_TOKEN_NAME } from './constants';

const ConfirmPage = React.lazy(() => import('./pages/ConfirmPage.tsx'));
const AccountPage = React.lazy(() => import('./pages/AccountPage.tsx'));
const MainPage = React.lazy(() => import('./pages/MainPage.tsx'));
const MyProfilePage = React.lazy(() => import('./pages/MyProfilePage.tsx'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage.tsx'));
const LoginPage = React.lazy(() => import('./pages/LoginPage.tsx'));
const SearchPage = React.lazy(() => import('./pages/SearchPage.tsx'));
const LecturePage = React.lazy(() => import('./pages/LecturePage.tsx'));
const WritePage = React.lazy(() => import('./pages/WritePage.tsx'));
const EditPage = React.lazy(() => import('./pages/EditPage.tsx'));
const SupportPage = React.lazy(() => import('./pages/SupportPage.tsx'));
const InquiryPage = React.lazy(() => import('./pages/InquiryPage.tsx'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage.tsx'));
const ShopPage = React.lazy(() => import('./pages/ShopPage.tsx'));
const PolicyPage = React.lazy(() => import('./pages/PolicyPage.tsx'));
const RegisterPage = React.lazy(() => import('./pages/RegisterPage.tsx'));
const NoticePage = React.lazy(() => import('./pages/NoticePage.tsx'));
const MigrationPage = React.lazy(() => import('./pages/MigrationPage.tsx'));
const FindUsernamePage = React.lazy(
  () => import('./pages/FindUsernamePage.tsx')
);
const FindPasswordPage = React.lazy(
  () => import('./pages/FindPasswordPage.tsx')
);
const ResetPasswordPage = React.lazy(
  () => import('./pages/ResetPasswordPage.tsx')
);

declare global {
  interface Window {
    adsbygoogle: unknown;
  }
}

const App: React.FC = () => {
  const location = useLocation();

  const { isLoading, refetch: refetchAuthInfo } = useAuthInfoQuery({
    onError: alertApiError,
  });

  const getIsLogined = () => !!StorageManager.getItem(AUTH_TOKEN_NAME);

  useEffect(() => {
    if (getIsLogined()) {
      refetchAuthInfo();
    }
  }, [location, refetchAuthInfo]);

  if (isLoading && getIsLogined()) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="confirm/:token" element={<ConfirmPage />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="shop" element={<ShopPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="login/migrate" element={<MigrationPage />} />
        <Route path="lectures/:lecture_id" element={<LecturePage />} />
        <Route path="lectures/:lecture_id/write" element={<WritePage />} />
        <Route
          path="lectures/:lecture_id/evals/:eval_id/edit"
          element={<EditPage />}
        />
        <Route path="notices/:id" element={<NoticePage />} />
        <Route path="support/*" element={<SupportPage />} />
        <Route path="inquiry/*" element={<InquiryPage />} />
        <Route path="account" element={<AccountPage />} />
        <Route path="myprofile/*" element={<MyProfilePage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="policy/*" element={<PolicyPage />} />
        <Route path="find/username" element={<FindUsernamePage />} />
        <Route path="find/password" element={<FindPasswordPage />} />
        <Route path="resetPassword/:token" element={<ResetPasswordPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <PopupPortal />
    </Suspense>
  );
};

export default observer(App);
